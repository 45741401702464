<template>
  <div class="n-header">
    <div class="container">
      <!-- logo -->
      <div class="n-logo">
        <!-- <img src="/imgs/logo.png" alt="" /> -->
        <h2>游点好玩官网</h2>
      </div>

      <!-- 导航 -->
      <div class="n-list">
        <a href="">网站首页</a>
        <a href="/#/ac1">收费资讯</a>
        <a href="/#/ac2">纠纷处理</a>
        <a href="/#/xy">用户服务协议</a>
        <a href="/#/ac3">用户权益保障措施</a>
      </div>

      <!-- 登录注册 -->
      <div class="n-login">
        <!-- 登录 -->
        <span class="n-login" @click="goLogin()" v-show="this.LoggedShow"> 登录 </span>
        <i v-show="this.LoggedShow">|</i>
        <!-- 注册 -->
        <span class="n-login" @click="goLogin()" v-show="this.LoggedShow">
          立即注册
        </span>
        <!-- 用户名 -->
        <span class="n-info" v-show="!this.LoggedShow" v-text="this.nv_user.Username"
          ><i class="iconfont icon-denglu"></i
        ></span>
        <!-- 退出登录 -->
        <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i>
          退出登录
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },

    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      location.reload();
      // this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.n-header {
  width: 100%;
  height: 80px;
  background: #fff;
  position: relative;
  border-bottom: 1px solid $colorZ;
  .container {
    height: 100%;
    @include flex();

    // logo
    .n-logo {
      width: 200px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      h2 {
        font-size: 16px;
      }
    }
    // 导航
    .n-list {
      a {
        display: inline-block;
        width: 120px;
        height: 30px;
        // background-color: $colorZ;
        border: 1px dashed $colorZ;
        margin: 0 12px;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        color: #333;
        transition: all 0.5s;
        &:hover {
          background-color: $colorZ;
          color: #fff;
          border-radius: 20px;
        }
      }
    }
    // 登录注册
    .n-login {
      span {
        font-size: 14px;
        color: #333;
        cursor: pointer;
        // transition: all 0.3s;
        &:hover {
          font-weight: bold;
          color: $colorZ;
        }
      }
      i {
        margin: 0 5px;
      }
    }
  }
}
</style>
